import React from 'react';
import { KE,NG,ZA,TZ,ET } from 'country-flag-icons/react/3x2';

function Home(){
	return(
		<div className="hero min-h-screen" style={{ backgroundImage: `url("/victory.jpg")` }}>
		  <div className="hero-overlay bg-opacity-60"></div>
		  <div className="hero-content text-center text-primary-content">
		    <div className="max-w-md">
		      <h1 className="mb-5 text-5xl font-bold">Shinda Leo!</h1>
		      <p className="mb-5">We think you may have heard about this. If not, don’t worry—lotteries are created to create excitement and get people talking. Lotteries have the power to change lives and affect economies, so we’re doing our best to roll out a new game with life-changing implications.</p>
		      <div className="flex">
		      <a href="https://ke.shindaleo.com" target="_blank" className="flex-1"><KE className="mask mask-squircle w-12" /></a>
		      <a href="https://ng.shindaleo.com" target="_blank" className="flex-1"><NG className="mask mask-squircle w-12" /></a>
		      <a href="https://tz.shindaleo.com" target="_blank" className="flex-1"><TZ className="mask mask-squircle w-12" /></a>
		      <a href="https://za.shindaleo.com" target="_blank" className="flex-1"><ZA className="mask mask-squircle w-12" /></a>
		      <a href="https://et.shindaleo.com" target="_blank" className="flex-1"><ET className="mask mask-squircle w-12" /></a>
		      </div>
		    </div>
		  </div>
		</div>
	);
}

export default Home;
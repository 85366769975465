import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';

function Contact(){
	const form = useRef();

	const handleOnSubmit = (e) => {
	    e.preventDefault();
	    emailjs.sendForm('service_szmz156', 'template_r9tnn6t', form.current, 'dYUCSTRo7McrA-X1A')
	      .then((result) => {
	        console.log(result.text);
	        Swal.fire({
	          icon: "success",
	          title: "Message Sent Successfully"
	        })
	      }, (error) => {
	        console.log(error.text);
	        Swal.fire({
	          icon: "error",
	          title: "Ooops, something went wrong",
	          text: error.text,
	        })
	      });
	    e.target.reset()
	  };
	return(
		<div className="hero min-h-screen bg-base-200">
		  <div className="hero-content flex-col lg:flex-row-reverse">
		    <div className="text-center lg:text-left">
		      <h1 className="text-5xl font-bold">Slide in the DMs</h1>
		      <p className="py-6">Enter your details and we'll connect you with the right person.</p>
		    </div>
		    <div className="card flex-shrink-0 w-full max-w-sm shadow-2xl bg-base-100">
		      <div className="card-body">
		        <form ref={form} onSubmit={handleOnSubmit}>
		        <div className="form-control">
		          <label className="label">
		            <span className="label-text">Email</span>
		          </label>
		          <input type="text" placeholder="Email" name="user_email" className="input input-bordered" />
		        </div>
		        <div className="form-control">
		          <label className="label">
		            <span className="label-text">Name</span>
		          </label>
		          <input type="text" placeholder="Name" name="user_name" className="input input-bordered" />
		          
		        </div>
		        <div className="form-control">
		        <label className="label">
		           <span className="label-text">Message</span>
		         </label>
		        <textarea className="textarea input-bordered" name="message" placeholder="Message"></textarea>
		        </div>
		        <div className="form-control mt-6">
		          <input type="submit" className="btn btn-primary" value="Send" />
		        </div>
		        </form>
		      </div>
		    </div>
		  </div>
		</div>
	);
}

export default Contact
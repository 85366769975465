import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./components/pages/Home";
import About from "./components/pages/About";
import Contact from "./components/pages/Contact";

function App() {
  return (
    <>
    <BrowserRouter>
      <Header />
        <div>
        <Routes>
          <Route exact path="/" element={<Home/>}/>
      
          <Route exact path="/about" element={<About/>}/>
            
          <Route exact path="/contact" element={<Contact/>}/>
           
        </Routes>
        </div>
      </BrowserRouter>
      <Footer />
    </>
  );
}

export default App;

import React from 'react';
import { KE,NG,ZA,TZ,ET } from 'country-flag-icons/react/3x2';

function About(){
	return(
		<div className="hero min-h-screen bg-base-200">
		  <div className="hero-content flex-col lg:flex-row">
		    <img src="/victory.jpg" className="max-w-sm rounded-lg shadow-2xl" />
		    <div>
		      <h1 className="text-5xl font-bold">About</h1>
		      <p className="py-6">Join the Shindaleo community and you can be part of our lottery daily jackpot. Play and win big!</p>
		      <div className="flex">
		      <a href="https://ke.shindaleo.com" target="_blank" className="flex-1"><KE className="mask mask-squircle w-12" /></a>
		      <a href="https://ng.shindaleo.com" target="_blank" className="flex-1"><NG className="mask mask-squircle w-12" /></a>
		      <a href="https://tz.shindaleo.com" target="_blank" className="flex-1"><TZ className="mask mask-squircle w-12" /></a>
		      <a href="https://za.shindaleo.com" target="_blank" className="flex-1"><ZA className="mask mask-squircle w-12" /></a>
		      <a href="https://et.shindaleo.com" target="_blank" className="flex-1"><ET className="mask mask-squircle w-12" /></a>
		      </div>
		    </div>
		  </div>
		</div>
	);
}

export default About;